import { useQuery } from '@tanstack/react-query';

const blacklist = ['sandyYpbDQoPNP6gAhtaeXs6wNSp6xbi1hioka9zFP4'];

export type DST = {
    address: string;
    data: {
        tokenMint: string;
        operator: string;
        partner: string;
        vsolReserves: string;
        lifetimeOperatorFees: string;
        unclaimedOperatorFees: string;
        lifetimePartnerFees: string;
        unclaimedPartnerFees: string;
        bump: number;
        baseFee: number;
        operatorFee: number;
        pendingOperator: string;
    };
    directorAddress: string;
    director: {
        stakeTarget: string;
        lastUpdatedAt: string;
    };
    metadata: {
        key: number;
        updateAuthority: string;
        mint: string;
        data: {
            name: string;
            symbol: string;
            uri: string;
            sellerFeeBasisPoints: number;
            creators: Array<{
                address: string;
                verified: number;
                share: number;
            }>;
        };
        primarySaleHappened: number;
        isMutable: number;
        name: string;
        symbol: string;
        description: string;
        image: string;
        showName: boolean;
        twitter: string;
        telegram: string;
        website: string;
        discord: string;
        decimals: number;
    };
};

export default function useGetDSTList() {
    return useQuery({
        queryKey: ['dstList'],
        queryFn: async () => {
            // Fetch from github
            const result = await fetch(
                'https://raw.githubusercontent.com/SolanaVault/vault-lst-list-generator/refs/heads/main/lst-list.json',
            );
            const data = (await result.json()) as DST[];
            return data.filter(
                (dst) => !blacklist.includes(dst.data.tokenMint.toString()),
            );
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        gcTime: 3600 * 1000 * 24,
    });
}
