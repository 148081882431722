exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dapp-airdrop-tsx": () => import("./../../../src/pages/dapp/airdrop.tsx" /* webpackChunkName: "component---src-pages-dapp-airdrop-tsx" */),
  "component---src-pages-dapp-claim-tsx": () => import("./../../../src/pages/dapp/claim.tsx" /* webpackChunkName: "component---src-pages-dapp-claim-tsx" */),
  "component---src-pages-dapp-direct-tsx": () => import("./../../../src/pages/dapp/direct.tsx" /* webpackChunkName: "component---src-pages-dapp-direct-tsx" */),
  "component---src-pages-dapp-index-tsx": () => import("./../../../src/pages/dapp/index.tsx" /* webpackChunkName: "component---src-pages-dapp-index-tsx" */),
  "component---src-pages-dapp-info-tsx": () => import("./../../../src/pages/dapp/info.tsx" /* webpackChunkName: "component---src-pages-dapp-info-tsx" */),
  "component---src-pages-dapp-lst-claim-[id]-tsx": () => import("./../../../src/pages/dapp/lst/claim/[id].tsx" /* webpackChunkName: "component---src-pages-dapp-lst-claim-[id]-tsx" */),
  "component---src-pages-dapp-lst-mint-tsx": () => import("./../../../src/pages/dapp/lst/mint.tsx" /* webpackChunkName: "component---src-pages-dapp-lst-mint-tsx" */),
  "component---src-pages-dapp-lst-stake-[id]-tsx": () => import("./../../../src/pages/dapp/lst/stake/[id].tsx" /* webpackChunkName: "component---src-pages-dapp-lst-stake-[id]-tsx" */),
  "component---src-pages-dapp-lst-temp-2-tsx": () => import("./../../../src/pages/dapp/lst/temp2.tsx" /* webpackChunkName: "component---src-pages-dapp-lst-temp-2-tsx" */),
  "component---src-pages-dapp-lst-temp-tsx": () => import("./../../../src/pages/dapp/lst/temp.tsx" /* webpackChunkName: "component---src-pages-dapp-lst-temp-tsx" */),
  "component---src-pages-dapp-lst-unstake-[id]-tsx": () => import("./../../../src/pages/dapp/lst/unstake/[id].tsx" /* webpackChunkName: "component---src-pages-dapp-lst-unstake-[id]-tsx" */),
  "component---src-pages-dapp-pools-tsx": () => import("./../../../src/pages/dapp/pools.tsx" /* webpackChunkName: "component---src-pages-dapp-pools-tsx" */),
  "component---src-pages-dapp-subscriptions-admin-tsx": () => import("./../../../src/pages/dapp/subscriptions/admin.tsx" /* webpackChunkName: "component---src-pages-dapp-subscriptions-admin-tsx" */),
  "component---src-pages-dapp-subscriptions-create-tsx": () => import("./../../../src/pages/dapp/subscriptions/create.tsx" /* webpackChunkName: "component---src-pages-dapp-subscriptions-create-tsx" */),
  "component---src-pages-dapp-subscriptions-index-tsx": () => import("./../../../src/pages/dapp/subscriptions/index.tsx" /* webpackChunkName: "component---src-pages-dapp-subscriptions-index-tsx" */),
  "component---src-pages-dapp-unstake-tsx": () => import("./../../../src/pages/dapp/unstake.tsx" /* webpackChunkName: "component---src-pages-dapp-unstake-tsx" */),
  "component---src-pages-dapp-validator-dashboard-tsx": () => import("./../../../src/pages/dapp/validatorDashboard.tsx" /* webpackChunkName: "component---src-pages-dapp-validator-dashboard-tsx" */),
  "component---src-pages-dapp-validators-index-tsx": () => import("./../../../src/pages/dapp/validators/index.tsx" /* webpackChunkName: "component---src-pages-dapp-validators-index-tsx" */),
  "component---src-pages-dapp-validators-validator-vote-key-index-tsx": () => import("./../../../src/pages/dapp/validators/[validatorVoteKey]/index.tsx" /* webpackChunkName: "component---src-pages-dapp-validators-validator-vote-key-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

