import { getAssociatedTokenAddressSync } from '../../node_modules/@solana/spl-token';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey } from '@solana/web3.js';
import BigNumber from 'bignumber.js';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

export default function useTokenBalance(mint?: PublicKey) {
    const { connection } = useConnection();
    const { publicKey } = useWallet();

    return useQuery({
        queryKey: [
            'tokenBalance',
            {
                mint: mint?.toString(),
                publicKey: publicKey?.toString(),
            },
        ],
        queryFn: async () => {
            invariant(publicKey, 'wallet not connected');
            invariant(mint, 'mint not found');
            const ata = getAssociatedTokenAddressSync(
                mint,
                new PublicKey(publicKey.toString()),
            );

            try {
                const balance = await connection.getTokenAccountBalance(
                    ata,
                    'processed',
                );
                return new BigNumber(balance.value.amount);
            } catch (e) {
                // Balance not found
                return new BigNumber(0);
            }
        },
        enabled: !!publicKey,
    });
}
