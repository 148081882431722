import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import DarkToggler from '../../DarkToggler';
import tw, { css } from 'twin.macro';
import { Logo } from '../../common/Logo';
import { useUserPoints } from '../hooks/useUserPoints';
import { SimpleButton } from '../../common/ui';
import { POINTS_TOKEN, VSOL_TOKEN_OBJ } from '../../../constants';
import { TokenIcon } from '../TokenIcon';
import { useGeneratedValue } from '../pages/PoolsPage/hooks/useGeneratedValue';
import { formatNumberWithSISuffix } from '../../../helpers/format';
import { WalletButton } from '../../common/WalletButton';
import { MoreInfo } from './MoreInfo';
import useGetDSTList from '../../../hooks/lst/useGetDSTList';
import { useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

const UserPointsInner: React.FC<{
    get: () => number;
}> = ({ get }) => {
    const value = useGeneratedValue(get);
    return <>{value !== null ? formatNumberWithSISuffix(value) : '--'}</>;
};

const UserPoints: React.FC = () => {
    const calculateUserPoints = useUserPoints();
    const { wallet } = useWallet();
    if (!wallet) {
        return null;
    }
    return (
        <Link to="/dapp/pools">
            <SimpleButton
                $size="xs"
                $variant="transparent-outline"
                tw="flex items-center gap-1"
            >
                <TokenIcon token={POINTS_TOKEN} />
                <div tw="text-left">
                    <UserPointsInner get={calculateUserPoints} />
                </div>
            </SimpleButton>
        </Link>
    );
};

const DSTPartnerClaim: React.FC = () => {
    const { data: dstList } = useGetDSTList();
    const { wallet } = useWallet();

    const dst = useMemo(() => {
        return dstList?.find(
            (dst) =>
                dst.data.partner.toString() ===
                wallet?.adapter.publicKey?.toString(),
        );
    }, [wallet, dstList]);

    if (!dst || dst.data.unclaimedPartnerFees === 0n) {
        return null;
    }

    return (
        <Link to={`/dapp/lst/claim/${dst.director?.stakeTarget.toString()}`}>
            <SimpleButton
                $size="xs"
                $variant="transparent-outline"
                tw="flex items-center gap-1"
            >
                <TokenIcon token={VSOL_TOKEN_OBJ} />
                <div tw="text-left">
                    Claim{' '}
                    {Number(dst.data.unclaimedPartnerFees) / LAMPORTS_PER_SOL}{' '}
                    partner fees
                </div>
            </SimpleButton>
        </Link>
    );
};

const NavLink: React.FC<{ to: string; label: string }> = ({ to, label }) => (
    <Link
        to={to}
        activeClassName="active"
        css={css`
            ${tw`font-medium text-sm md:text-base border-b-2 border-transparent transition-colors px-2 py-1`}
            &.active {
                ${tw`border-vault-dark`}
            }
            &:not(.active) {
                ${tw`text-secondary`}
                &:hover {
                    ${tw`text-black dark:text-white`}
                }
            }
        `}
    >
        {label}
    </Link>
);

export const Navbar: React.FC = () => {
    const [scrolledFromTop, setScrolledFromTop] = useState(false);

    const onScroll = (e: Event) => {
        setScrolledFromTop(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (e.target.documentElement as Document).scrollTop > 10,
        );
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    }, []);

    return (
        <header
            tw="top-0 z-50 w-full items-center fixed transition-all"
            css={[scrolledFromTop && tw`bg-cultured/80 dark:bg-black/80`]}
        >
            <div tw="w-full mx-auto px-2 md:px-5">
                <div tw="relative -mx-4 flex gap-2 items-center">
                    <div tw="px-4">
                        <Link
                            to="/"
                            tw="w-full py-2 md:py-5 flex items-center gap-1"
                        >
                            <Logo />
                        </Link>
                    </div>
                    <nav tw="flex items-center">
                        <div tw="mx-auto pointer-events-auto flex items-center gap-2 md:(ml-10 gap-4)">
                            <NavLink to="/dapp" label="Stake" />
                            <NavLink to="/dapp/unstake" label="Unstake" />
                            <MoreInfo />
                        </div>
                    </nav>
                    <div tw="flex gap-2 items-center grow justify-end">
                        <DSTPartnerClaim />
                        <UserPoints />
                        <div tw="hidden md:flex">
                            <DarkToggler />
                        </div>
                        <div tw="flex items-center justify-end px-4">
                            <div tw="flex justify-end">
                                <div tw="flex">
                                    <WalletButton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div tw="w-full bg-gradient-to-r from-[#ffad03] to-[#FC5F15] p-3 text-white">
                <div tw="flex justify-between md:text-base text-xs flex-col md:flex-row">
                    <div tw="text-center w-full">
                        The Vault{' '}
                        <Link to={'/dapp/pools'} tw="underline">
                            points program
                        </Link>{' '}
                        is live! Stake your Vault LSTs to earn Point Tokens and
                        increase your rewards.{' '}
                        <a
                            href="https://docs.thevault.finance/about/points-program"
                            target="_blank"
                            tw="underline"
                            rel="noreferrer"
                        >
                            Learn how it works
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
};
