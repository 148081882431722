import { Popover } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import tw, { css } from 'twin.macro';
import { DISCORD_LINK, TWITTER_LINK } from '../../../constants';
import { IoShieldCheckmarkOutline } from 'react-icons/io5';
import { FaPercent, FaRegHandPointRight } from 'react-icons/fa6';
import { GiAbacus, GiReceiveMoney } from 'react-icons/gi';
import { Link } from 'gatsby';

const InfoItemLink = tw(Link)``;

const InfoItem: React.FC<{
    icon: React.ReactNode;
    title: string;
    description: string;
    link: string;
}> = ({ icon, title, description, link }) => {
    return (
        <InfoItemLink
            to={link}
            tw="grid gap-4 [grid-template-columns: 20px 1fr] w-full px-4"
            css={css`
                &:hover {
                    ${tw`text-vault-light!`}
                }
            `}
        >
            <div
                tw="pt-2"
                css={css`
                    & > svg {
                        ${tw`text-vault-light w-5 h-5`}
                    }
                `}
            >
                {icon}
            </div>
            <div tw="text-left">
                <span
                    tw="font-semibold dark:text-white whitespace-nowrap text-vault-light"
                    css={css`
                        ${
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            InfoItemLink
                        }:hover & {
                            ${tw`text-vault-light`}
                        }
                    `}
                >
                    {title}
                </span>
                <p
                    tw="text-xs text-secondary"
                    css={css`
                        ${
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            InfoItemLink
                        }:hover & {
                            ${tw`text-vault-light`}
                        }
                    `}
                >
                    {description}
                </p>
            </div>
        </InfoItemLink>
    );
};

export const MoreInfo: React.FC = () => {
    return (
        <Popover tw="relative inline-block text-left h-full">
            {({ open }) => (
                <>
                    <Popover.Button
                        tw="font-medium text-sm md:text-base border-b-2 border-transparent transition-colors px-2 py-1"
                        css={css`
                            &.active,
                            &[data-headlessui-state='open'] {
                                ${tw`border-vault-dark`}
                            }
                            &:not(&[data-headlessui-state='open']) {
                                ${tw`text-secondary`}
                                &:hover {
                                    ${tw`text-black dark:text-white`}
                                }
                            }
                        `}
                    >
                        More
                    </Popover.Button>
                    <AnimatePresence>
                        {open && (
                            <Popover.Panel
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                                static={'true' as any}
                                tw="fixed w-screen md:(absolute w-[300px]) z-50 left-0 mt-2 origin-top-right bg-white shadow-sm dark:(bg-fg-dark border border-cultured/10 ring-0) rounded-lg flex flex-col ring-gray-100 ring-1"
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                                as={motion.div}
                                initial={{
                                    opacity: 0,
                                    scale: 0.95,
                                }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{
                                    opacity: 0,
                                    scale: 0.95,
                                }}
                                transition={{
                                    duration: 0.1,
                                }}
                            >
                                <div tw="flex flex-col gap-4 py-5">
                                    <InfoItem
                                        icon={<IoShieldCheckmarkOutline />}
                                        title="Validators"
                                        description="View the validators that The Vault relies on to secure and decentralize the Solana network."
                                        link="/dapp/validators"
                                    />
                                    <InfoItem
                                        icon={<FaRegHandPointRight />}
                                        title="Direct Stake"
                                        description="Delegate your vSOL stake to your favorite validator."
                                        link="/dapp/direct"
                                    />
                                    <InfoItem
                                        icon={<FaPercent />}
                                        title="Earn Points and Rewards"
                                        description="Stake vSOL in DeFi projects to earn points and other rewards."
                                        link="/dapp/pools"
                                    />
                                    <InfoItem
                                        icon={<GiReceiveMoney />}
                                        title="Claim"
                                        description="Claim points."
                                        link="/dapp/claim"
                                    />
                                    <InfoItem
                                        icon={<GiAbacus />}
                                        title="Validator Dashboard"
                                        description="Info and stats for your validator."
                                        link="/dapp/validatorDashboard"
                                    />
                                </div>
                                <div tw="text-sm font-medium flex items-center justify-start px-4 py-3 gap-6 bg-cultured/5 w-full">
                                    <a
                                        href={TWITTER_LINK}
                                        target="_blank"
                                        rel="noreferrer"
                                        tw="flex items-center gap-2 hover:text-vault-light"
                                    >
                                        <FaTwitter tw="w-4" />
                                        <span>Twitter</span>
                                    </a>
                                    <a
                                        href={DISCORD_LINK}
                                        target="_blank"
                                        rel="noreferrer"
                                        tw="flex items-center gap-2 hover:text-vault-light"
                                    >
                                        <FaDiscord tw="w-4" />
                                        <span>Discord</span>
                                    </a>
                                </div>
                            </Popover.Panel>
                        )}
                    </AnimatePresence>
                </>
            )}
        </Popover>
    );
};
